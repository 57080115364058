//news article card
.article.card {
    width: 100%!important;
    background-color: white;
    border: none;


    img {
        mask-image: url("build/src/img/news-img-mask.png");
        -webkit-mask-position: center;
        -webkit-mask-size: contain;
        -webkit-mask-repeat: no-repeat;
        transition: transform .5s ease;
    }

    &:hover {
        img {
            transform: scale(1.05);
        }
    }

    h4 {
        margin-bottom: 8px;
        font-weight: bold;
        color: $content;
        text-align: left;
        letter-spacing: 0.07em;
        display: -webkit-box;
        vertical-align: super;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .date {
        text-align: left;
        color: $content;
        font-size: 18px;
        margin-bottom: 20px;

        &:after {
            content: '';
            display: block;
            width: 150px;
            height: 2px;
            background-color: $primary-accent;
            border-radius: 50px;
            margin: 20px 0;
        }
    }

    p {
        line-height: 1.5;
        text-align: justify;
        margin-bottom: 30px;
    }

    .more-link {
        color: $secondary;
    }

    @include media-breakpoint-down(sm) {
        .card-img-wrapper {
            margin-bottom: 25px;
        }

        .card-title, .date {
            text-align: center;
        }

        .date:after {
            margin: 30px auto;
        }

        .card-text {
            display: none;
        }

        .btn-wrapper {
            text-align: center;
        }
    }
}
