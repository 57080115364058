button {
    background: transparent;
    border: none;
}

.btn.primary {
    background-color: $primary;
    padding: 8px 40px;
    color: $secondary;
    font-weight: 600;
    border-radius: 50px;

    &:hover {
        background-color: $secondary;
        color: white;
    }
}

.btn.secondary {
    background-color: $secondary;
    padding: 8px 40px;
    color: white;
    border-radius: 50px;
    font-weight: 600;

    &:hover {
        background-color: $accent;
        color: $secondary;
    }
}

.btn.white {
    background-color: white;
    padding: 8px 40px;
    color: $secondary;
    border: 1px solid $secondary;
    border-radius: 50px;
    margin-bottom: 10px;
    margin-right: 5px;
    margin-left: 5px;
    font-weight: 600;

    &:hover {
        background: $secondary;
        color: white;
        border: 1px solid $secondary;
    }
}

.more {
    display: flex;
    justify-content: center;
}