.menu-inner {
    .subheader {
        background: none;
    }

    .menu-info {
        padding: 60px 0;

        .more {
            margin-bottom: 40px;

            a {
                margin: 0 20px;
                min-width: 150px;
            }
        }
    }

    @include media-breakpoint-down(sm)  {
        .container {
            padding-right: 30px;
            padding-left: 30px;
        }
    }

    .note p {
        max-width: 900px;
        margin: auto;
        padding-bottom: 150px;
    }
}

