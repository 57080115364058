.tab {
    ul {
        padding-left: 0;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        column-gap: 40px;

        li {
            text-align: center;
            margin-bottom: 20px;
            
            a {
                background: white;
                color: $secondary!important;
                display: block;
                padding: 10px;
                border: 1px solid $secondary;
                border-radius: 50px;
                font-size: 20px;
                font-weight: 600;

                &:hover {
                    background: $primary;
                    border-color: $primary;
                }

                &.active {
                    background: $primary;
                    border-color: $primary;
                }
            }
        }

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(3, 1fr);
            column-gap: 10px;

            li {
                a {
                    font-size: 18px;
                }
            }
        }
    }
}

.note {
    margin: auto;

    p {
        color: $content;
        text-align: justify;
        line-height: 1.5;
    }
}

