.contact {
    .contact-us-content {
        max-width: 700px!important;
        margin: auto;
        padding: 0px 0 100px;
    }

    .contact-info-wrapper {
        margin-bottom: 40px;
    }

    .note {
        margin-bottom: 60px;
        font-size: 18px;
        font-weight: bold;

        p {
            text-align: center;
        }
    }

    .contact-info {
        margin-bottom: 45px;
        border-bottom: 1px solid $secondary;

        @include media-breakpoint-up(md) {
            display: flex;
        }

        h5 {
            color: $footer-accent;
            margin-bottom: 10px;
            min-width: 200px
        }

        p {
            line-height: 3;
            position: relative;
            top: -13px;
        }

        a {
            color: $content;

            &:hover {
                color: $primary;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .container {
            padding-right: 30px;
            padding-left: 30px;
        }
    }
}