.blog {
    .blog-wrapper {
        padding: 0 30px 100px;
    }

    .blog-title-wrapper {
        align-items: center;

        .blog-meta-wrapper {
            @include media-breakpoint-down(lg) {
                margin-bottom: 30px;
            }
        }

        .period {
            color: $footer-accent;
            letter-spacing: 0.05em;
            font-size: 18px;
            font-weight: 500;

            span {
                color: $content;
            }
        }

        .title {
            color: $secondary;
            padding-bottom: 10px;
            margin-bottom: 15px;
            font-size: 30px;
            font-weight: bold;
            letter-spacing: 0.085em;
        }

        p {
            color: $content;
            line-height: 2;
            font-size: 16px;
        }

        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }
    }

    .blog-image-cover-wrapper {
        margin-bottom: 50px;

        img {
            mask-image: url("build/src/img/news-img-mask.png");
            -webkit-mask-position: center;
            -webkit-mask-size: contain;
            -webkit-mask-repeat: no-repeat;
            transition: transform .5s ease;
        }
    }

    .blog-content-wrapper {
        margin-bottom: 60px;

        p {
            color: $content;
            line-height: 2;
        }

        label {
            color: $footer-accent;
            font-size: 18px;
            font-weight: 500;
        }
    }

    .pagination {
        padding-bottom: 80px;
        padding-right: 0;

        .row {
            width: 100%;
            margin: auto;

            @include media-breakpoint-down(sm) {
                padding-top: 20px;
                margin-right: 30px;
                margin-left: 30px;
                border-top: 1px dashed $secondary;
            }
        }

        img {
            width: 20px;
        }

        p {
            margin: 0;
        }

        .prev-blog a, .next-blog a {
            display: flex;
            align-items: center;
        }

        .prev-blog a {
            img {
                transition: transform 0.3s ease;
            }

            &:hover {
                img {
                    transform: translateX(-5px);
                }
            }
    
        }

        .next-blog a {
            justify-content: flex-end;

            img {
                transform: rotateY(180deg);
                transition: transform 0.3s ease;
            }

            &:hover {
                img {
                    transform: rotateY(180deg) translateX(-5px);
                }
            }
        }

        .placeholder {
            position: relative;
            top: -10px;
            border-bottom: 1px dashed $secondary;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    .blog-btn {
        text-align: center;
        padding-bottom: 100px;

        @include media-breakpoint-down(sm) {
            padding-bottom: 200px;
        }
    }
}