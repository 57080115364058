.branch {
    .branch-content-wrapper {
        padding: 0 30px;
        padding-bottom: 150px;
    }

    .note {
        p {
            color: #A10A1C;
            line-height: 2;
        }
    }

    .store-content {
        .store-detail {
            border-bottom: 1px solid $secondary;
            padding-bottom: 30px;
            margin-bottom: 30px;
        }

        .store-notice {
            color: $secondary;
            font-size: 16px;
            margin-bottom: 20px;
            background: #FFF0C9;
            border-radius: 20px;
            text-align: left;
            padding: 3px 10px;
            letter-spacing: 0.07em;

            @include media-breakpoint-down(md) {
                padding: 10px;
            }
        }

        .store-name {
            color: $secondary;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        .address {
            color: $secondary;
            margin-bottom: 40px;
        }

        .store-col-two {
            .tel {
                text-align: center;
                text-decoration: underline;
                margin-bottom: 20px;
            }

            @include media-breakpoint-down(md) {
                .row {
                    align-items: center;

                    .tel {
                        text-align: left;
                    }
                }
            }
        }

        a {
            color: $secondary;

            &:hover {
                color: $primary-accent;
            }
        }

        .links {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 20px;

            a {
                padding: 9px 10px;
                border: 2px solid $secondary;
                border-radius: 50px;
                line-height: 1;
                margin: 0 5px;
                min-width: 56px;
                max-height: 54px;

                &:hover {
                    background: $secondary;
                    color: white;
                }
            }
        }
    }
}