.section-title {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 4rem;

    img {
        max-width: 180px;
    }
}

.subheader {
    padding: 8rem 0 4rem;

    .section-title {
        margin: 0;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 200px;
    }
}