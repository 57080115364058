.error404 {
    .error-message-wrapper {
        display: grid;
        justify-content: center;
        text-align: center;
        padding: 200px 30px 150px;
        height: 100%;

        img {
            width: 90px;
            margin: auto;
            margin-bottom: 50px;
        }

        .title {
            font-size: 26px;
            font-weight: 600;
            line-height: 1.7;
        }

        .subtitle {
            line-height: 2;
        }
    }

    .more {
        margin-top: 40px;
    }
}