.event {
    #content {
        padding-bottom: 150px;
    }

    .event-feature {
        padding-bottom: 20px;

        .row {
            height: 100%;
            align-items: center;
        }

        img {
            mask-image: url("build/src/img/news-img-mask.png");
            -webkit-mask-position: center;
            -webkit-mask-size: contain;
            -webkit-mask-repeat: no-repeat;
            transition: transform .5s ease;
        }

        p {
            font-size: 20px;
            font-weight: 600;
            line-height: 2;
        }

        @include media-breakpoint-down(sm) {
            .row {
                flex-direction: column-reverse;
            }

            p {
                font-size: 16px;
                margin-bottom: 30px;
            }

            .text-wrapper {
                margin-bottom: 40px;
                padding-right: 30px;
                padding-left: 30px;
            }

            .img-wrapper {
                margin-bottom: 30px;
            }
        }

        @include media-breakpoint-down(sm) {
            .btn-wrapper {
                text-align: center;
            }
        }
    }

    .timeline {
        background: url("build/src/img/yellow-bg.png"); 
        background-size: cover;
        padding-bottom: 150px;

        .div {
            position: relative;
            top: -2px;
        }

        .timeline-content-wrapper {
            padding: 100px 0;
            text-align: center;
            position: relative;
        }

        .title {
            padding: 50px 0 100px; 

            img {
                max-width: 200px;
            }
        }

        ul {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            padding-left: 0;

            li {
                margin-bottom: 100px;
                position: relative;
                max-height: 300px;
                min-height: 300px;


                .date {
                    color: $secondary;
                    font-weight: 600;
                    font-size: 20px;
                    letter-spacing: 0.08em;
                    margin-bottom: 10px;
                }
            }

            h4 {
                height: 100%;
                writing-mode: vertical-rl;
                text-orientation: upright;
                margin: auto;
                background: white;
                padding: 45px 15px 30px;
                border-radius: 12px;
                color: $secondary;
                letter-spacing: 0.4em;
                text-align: left;

                &:before {
                    content: '';
                    display: block;
                    width: 13px;
                    height: 13px;
                    background: $primary;
                    border-radius: 50px;
                    position: absolute;
                    transform: translate(-7px, -30px);
                }
            }

            @include media-breakpoint-down(md) {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        .cabbage-decor {
            img {
                max-width: 100px;
                position: absolute;
                right: -200px;
                bottom: 50%;
            }
        }
    }

    //footer
    footer {
        .wave {
            height: 450px;
            background: no-repeat bottom center url("build/src/img/event-footer-deco.png");
            background-size: contain;

            @media only screen and (min-width: 2300px) {
                background-size: cover;
            }

            img {
                display: none;
            }
        }
    }
}