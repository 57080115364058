.home {
    //content 
    #content {
        padding-bottom: 170px;

        @include media-breakpoint-down(sm) {
            padding-bottom: 250px;
        }
    }

    .popup-msg {
        display: none;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0,0,0,0.5);
    }

    .popup-msg.show {
        display: block;
        position: fixed;
        z-index: 500;

        .popup-content-wrapper {
            position: relative;
            align-items: center;
            margin: auto;
            padding: 20px;
            display: grid;
            height: 100%;
        }

        .popup-content-inner-wrapper {
            position: relative;
            width: max-content;
            margin: auto;
            max-width: 800px;
            height: auto;
        }

        .popup-remove {
            position: absolute;
            z-index: 300;
            top: -20px;
            right: -20px;
            cursor: pointer;
            color: $primary;
            font-size: 50px;

            &:after {
                content: '';
                display: block;
                width: 45px;
                height: 45px;
                background: white;
                position: absolute;
                top: 3px;
                left: 1px;
                border-radius: 50px;
                z-index: -1;
            }

        }

        .popup-content {
            // border: 10px solid $primary;
            border-radius: 5px;
            // background: $primary;
            padding: 0;
            overflow: hidden;

            @media only screen and (max-width: 576px) {
                max-width: 400px;
            }

            @media only screen and (max-width: 450px) {
                max-width: 330px;
                // border: 5px solid $primary;
            }
        }
    }

    #banner {
        .slider-cover {
            position: absolute;
            bottom: -1px;
            z-index: 1;
        }
    }


    //跑馬燈-----------------------------
    marquee {
        background: no-repeat url('build/src/img/yellow-bg.png');
        color: $content;
        font-size: 20px;
        font-weight: 600;
        line-height: 46px;
        letter-spacing: 0.05em;
        display: flex;
        align-items: center;
        height: 100px;
        background-size: cover;

        a {
            color: $content;

            &:hover {
                text-decoration: underline;
            }
        }

        ul {
            display: flex;
            list-style-position: outside;
            margin-bottom: 0;

            li {
                &:before{
                    content: url('build/src/img/marquee-bullets.png');
                    position: relative;
                    top: 7px;
                    margin: 0 30px;
                }
            }
        }
    }

    //幸福資訊
    #news {
        padding-top: 85px;

        @include media-breakpoint-down(sm) {
            padding-top: 40px;

            .swiper-container {
                padding-bottom: 0;
            }
        }

        .article {
            .row {
                align-items: center;
            }
        }
    }

    #menu {
        background: no-repeat url("build/src/img/yellow-bg.png");
        background-size: cover;
        min-height: 1700px;

        @include media-breakpoint-down(lg) {
            min-height: 1500px;
        }

        @include media-breakpoint-down(sm) {
            min-height: 1100px;
        }

        .div-wrapper {
            position: relative;

            &:before {
                content: url("build/src/img/tonkasu-accent.svg");
                position: absolute;
                left: -400px;
                transform: scale(0.7);
                top: -300px;

                @include media-breakpoint-down(md) {
                    transform: scale(0.5);
                    left: -530px;
                    top: -350px;
                }

                @include media-breakpoint-down(sm) {
                    transform: scale(0.3);
                    left: -500px;
                    top: -400px;
                }
            }
        }  

        .div {
            position: relative;
            top: -1px;
            padding-bottom: 60px;
        }

        .row {
            &:before {
                content: url("build/src/img/tea-accent.svg");
                position: absolute;
                transform: scale(0.6);
                left: 38%;
                z-index: 2;
                top: 150px;
            }

            @include media-breakpoint-down(md) {
                &:before {
                    left: 57%;
                    top: 60px;
                }
            }

            @include media-breakpoint-down(sm) {
                &:before {
                    transform: scale(0.35);
                    left: 46%;
                    top: -30px;
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .section-title {
                margin-bottom: 0;
            }
        }

        .slider {
            z-index: 1;
            position: relative;
            top: 250px;
            mask-image: url("build/src/img/slider-mask.png");
            left: -5%;
            -webkit-mask-position: center;
            -webkit-mask-size: contain;
            -webkit-mask-repeat: no-repeat;

            @include media-breakpoint-down(md) {
                top: 0;
                left: -18%;
                transform: scale(0.8);
            }


        }

        .menu-content {
            position: relative;
            max-width: 1400px;
            margin: auto;

            &:before {
                content: url("build/src/img/波浪線.png");
                transform: scale(0.7);
                position: absolute;
                bottom: -500px;
                right: -400px;

                @include media-breakpoint-down(md) {
                    bottom: -200px;
                }

                @include media-breakpoint-down(sm) {
                    bottom: -300px;
                }
            }

            @include media-breakpoint-up(md) {
                position: relative;
                top: -100px;
            }
        }

        .text {
            background: no-repeat center url("build/src/img/menu-text-bg.png");
            background-size: contain;
            position: relative;
            min-height: 900px;
            aspect-ratio: 1/1;
            position: absolute;
            right: -5%;

            @include media-breakpoint-down(md) {
                position: relative;
                top: -30%;
                min-height: unset;
                background-position: center;
                background-size: cover;
                right: unset;
            }

            &:before {
                content: url("build/src/img/chopstick-accent.svg");
                position: absolute;
                transform: scale(0.6);
                top: 80px;
                left: -40px;

                @include media-breakpoint-down(md) {
                    right: -170px;
                    transform: scale(0.6) rotateY(180deg);
                    top: 0px;
                }

                @include media-breakpoint-down(sm) {
                    transform: scale(0.5) rotateY(180deg);
                    right: -160px;
                    top: -60px;
                }
            }

            &:after {
                content: url("build/src/img/soy-sauce-accent.svg");
                position: absolute;
                right: 0px;
                bottom: -50px;
                transform: scale(0.6);

                @include media-breakpoint-down(md) {
                    right: -90px;
                    bottom: -80px;
                }

                @include media-breakpoint-down(sm) {
                    transform: scale(0.3);
                    bottom: -190px;
                }
            }


            .content-wrapper {
                display: grid;
                align-items: center;
                height: 100%;
                justify-content: center;

                @include media-breakpoint-down(sm) {
                    height: 120%;
                }

                .content-inner-wrapper {
                    text-align: left;
                    padding: 6em 8em 6em 15em;

                    @include media-breakpoint-down(lg) {
                        padding: 6em 8em 6em 13em;
                    }

                    @include media-breakpoint-down(sm) {
                        padding: 6em 2em 6em 2em;
                    }

                    .content-title {
                        color: $secondary;
                        margin-bottom: 20px;

                        &:after {
                            content: '';
                            display: block;
                            width: 100px;
                            height: 2px;
                            background-color: $primary;
                            margin-top: 20px;
                        }
                    }

                    p {
                        text-align: justify;
                        margin-bottom: 30px;
                        line-height: 2;

                        @include media-breakpoint-down(sm) {
                            font-size: 14px;
                            display: -webkit-box;
                            vertical-align: super;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                    }

                    .btn.primary {
                        @include media-breakpoint-down(lg) {
                            float: right;
                        }
                    }
                }
            }
        }

        .rice-accent {
            position: absolute;
            width: 100%;
            max-width: 150px;
            right: 40%;
            transform: translateY(80px);

            @include media-breakpoint-down(md) {
                transform: translateY(-330px);
                left: 15%;
                width: 100px;
                right: unset;
            }

            @include media-breakpoint-down(sm) {
                width: 60px;
                transform: translateY(-230px);
            }
        }
    }
}