.menu {
    .menu-cover-wrapper {
        padding-bottom: 100px;
    }

    .menu-cover {
        position: relative;
        background-color: $secondary;
        padding: 0;
        mask-image: url("build/src/img/menu_mask.png");
        -webkit-mask-position: center;
        -webkit-mask-size: contain;
        -webkit-mask-repeat: no-repeat;

        h2 {
            position: absolute;
            color: white;
            z-index: 2;
            display: flex;
            height: 100%;
            align-items: center;
            width: 100%;
            text-align: center;
            justify-content: center;
        }

        a {
            color: white;
            display: flex;
            height: 100%;
            align-items: center;
            width: 100%;
            text-align: center;
            justify-content: center;
        }

        img {
            opacity: 0.6;
            transition: opacity 0.5s ease;
        }

        &:hover {
            background: $primary;

            h2 a {
                color: $secondary
            }
            
            img {
                opacity: 0;
            }
        }
    }

    .note {
        max-width: 960px;
        margin: auto;
        padding: 20px 0 100px;

        p {
            line-height: 2;
        }
    }
}