.join-us {
    background: no-repeat top center url("build/src/img/join-bg.png") ;
    background-size: inherit;


    .join-wrapper {
        margin-bottom: 150px;

        @include media-breakpoint-up(lg) {
            background: no-repeat center center url("build/src/img/join-content-bg.png");
            background-size: contain;
        }

        .social-wrapper {
            max-width: 1000px;
            margin: auto;
        }
    }

    .join-inner-wrapper {
        padding: 20px;

        a {
            padding: 20px;
            display: block;
            text-align: center;
        }

        h4 {
            margin-bottom: 30px;
            color: $secondary;
            font-weight: 600;
            letter-spacing: 0.1em;

            @include media-breakpoint-down(sm) {
                font-size: 20px;
            }
        }

        .thumbnail {
            max-width: 270px;
            background: white;
            filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.25));
            padding: 15px;
            transition: transform 0.3s ease;
        }

        .content-wrapper {
            text-align: center;
            margin: 40px 0;

            p {
                line-height: 2;
                font-weight: 600;
                font-size: 20px;

                &::after {
                    content: '';
                    display: block;
                    width: 200px; 
                    height: 1px;
                    background-color: $secondary;
                    margin: 50px auto;

                    @include media-breakpoint-down(sm) {
                        width: 100px;
                    }
                }  
            }

            .wowprime-logo {
                max-width: 200px;
                text-align: center;
            }

            .line-logo {
                max-width: 90px;
                text-align: center;
            }
        }

        @include media-breakpoint-up(lg) {
            position: relative;
            top: -50px;
        }

        @include media-breakpoint-down(sm) {
            padding: 25px 0px;
        }

        //hover效果
        &:hover {
            .thumbnail {
                transform: scale(1.05);
            }
        }
    }

    @include media-breakpoint-down(lg) {
        .wowprime {
            padding-bottom: 30px;
            border-bottom: 2px solid $secondary;
        }

        .line {
            padding-top: 50px;
        }
    }

}