.news {
    .news-content {
        padding-bottom: 150px;
        max-width: 1720px;
        margin: auto;
        padding-right: 30px;
        padding-left: 30px;

        .article {
            padding: 0 2.5em 2.5em;

            .row {
                background: white;
                box-shadow:  0px 0px 34px rgba(95, 62, 17, 0.1);
                border-radius: 60px;
                padding: 1em 1em;
                align-items: center;

                @include media-breakpoint-down(lg) {
                    padding: 30px 10px 10px;
                }
            }

            p {
                display: -webkit-box;
                vertical-align: super;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                margin-bottom: 10px;
            }

            .more-link {
                float: right;
                clear: both;
            }
        }

        @include media-breakpoint-down(lg) {
            .card-img-wrapper {
                margin-bottom: 25px;
            }
    
            .card-title, .date {
                text-align: center;
            }
    
            .date:after {
                margin: 30px auto;
            }
    
            .card-text {
                display: none;
            }
    
            .btn-wrapper {
                text-align: center;
            }
        }

        @include media-breakpoint-down(sm) {
            .article {
                padding: 0 1.5em 1.5em;

                .row {
                    border-radius: 40px;
                }
            }

            .card-img-wrapper {
                margin: 0;
            }
        }
    }
}