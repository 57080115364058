#main {
    background: no-repeat bottom center url("build/src/img/header-bg.png");
    background-size: cover;
    align-items: center;
    z-index: 10;
    height: 150px;
    padding-right: 4em;
    padding-left: 4em;
    justify-content: space-between;

    @include media-breakpoint-down (lg) {
        padding-right: 2em;
        padding-left: 2em;
    }

    @include media-breakpoint-down(md) {
        background: no-repeat bottom center url("build/src/img/mobile-header-bg.png");
        background-size: cover;
    }

    @media only screen and (max-width: 415px) {
        background-size: auto;
        background-position: top;
        padding-right: 1em;
        padding-left: 1em;
    }

    #logo {
        @media only screen and (max-width: 670px) {
            position: relative;
            top: -25px;
            
            img {
                width: 120px;
            }
        }
    }

    .hamburger-wrapper {
        cursor: pointer;
        position: relative;
        top: -10px;
        transition: transform 0.3s ease;

        &:hover {
            img {
                transform: scale(1.1);
            }
        }

        @media only screen and (max-width: 670px) {
            top: -27px;
            transform: scale(0.9);
        }
    }

    .hamburger  {
        display: grid;
        justify-content: end;
        text-align: center;

        img {
            transition: .5s ease;
            opacity: 1;
            width: 70px;
            height: auto;
            margin: auto;
            margin-bottom: 5px;
        }

        span {
            color: #A0620F;
            letter-spacing: 0.1em;
            font-weight: 500;
            font-size: 14px;
        }

        @media only screen and (max-width: 670px) {
            img {
                width: 50px;
            }
        }
    }

    #navBarMobile {
        z-index: 5000;
        position: absolute;
        top: 0;
        left: 0;
        background: $primary;
        height: 100vh;
        width: 100vw;
        transform: translateY(-100vh);
        transition: ease 1s;
        opacity: 0;

        .close {
            cursor: pointer;
            position: absolute;
            right: 2em;
            top: 2em;
            color: $content;
            opacity: 1;
            text-shadow: none;

            i {
                font-size: 40px;
            }
            
            &:hover{
                opacity: 0.5;
            }
        }

        .nav-content {
            display: grid;
            justify-content: center;
            height: 100%;
            align-items: center;
        }

        .logo {
            text-align: center;
            margin-bottom: 100px;

            img {
                max-width: 100px;
                margin: auto;
            }
        }

        ul {
            padding: 0;
            margin-top: 20px;

            li {
                display: grid;
                text-align: center;
                padding: 10px 20px;
                justify-content: center;

                @include media-breakpoint-down (sm) {
                    padding: 10px;
                }
    
                a {
                    color: $content;
                    display: grid;
                    padding: 10px;
                    position: relative;

                    .text-wrapper {
                        display: grid;
                    }

                    .en {
                        font-size: 16px;
                        text-transform: uppercase;
                        letter-spacing: 0.2em;
                        line-height: 23px;
                    }

                    .ch {
                        font-size: 26px;
                        letter-spacing: 0.4em;
                        line-height: 38px;
                    }

                    &:hover {
                        text-decoration: none;
                        font-weight: 500;
                    }

                    @include media-breakpoint-down(sm) {
                        .en {
                            font-size: 14px;
                            line-height: 19px;
                        }

                        .ch {
                            font-size: 18px;
                            line-height: 23px;
                        }
                    }
                }
            }
        }

        .inner-links {
            ul {
                column-count: 3;
                margin-bottom: 40px;
                border-top: 1px solid $content;
                border-bottom: 1px solid $content;

                @include media-breakpoint-down(sm) {
                    column-count: 2;
                }

                a {
                    display: flex;

                    @include media-breakpoint-up(md) {
                        &:hover:before {
                            content: url("build/src/img/menu-accent.svg");
                            position: absolute;
                            left: -35px;
                            bottom: 13px;
                        }
                    }
                }
            }
        }

        .outter-links {
            ul {
                column-count: 2;

                svg {
                    margin: auto;
                    margin-bottom: 20px;
                }

                a:hover {
                    path:first-of-type {
                        fill: $primary-accent;
                    }
                }

                a:first-of-type {
                    .ch {
                        position: relative;
                        left: 5px;
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .logo {
                margin-bottom: 50px;

                img {
                    width: 20%;
                }
            }

            .close {
                right: 1em;
                top: 1em;

                i {
                    font-size: 30px;
                }
            }
        }
    }

    #navBarMobile.active {
        transform: translateX(0);
        transition: ease 0.5s; 
        opacity: 1;
    }
}
