footer {
    position: absolute;
    width: 100%;
    bottom: 0;

    //join line message
    .join-line-message {
        position: fixed;
        right: -100%;
        bottom: 170px;
        z-index: 100;
        transition: ease 1s; 
        min-width: 250px;

        &.active {
            right: 20px;
            transition: ease 0.3s;
        }

        .card-header {
            background-color: #00B800;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: white;
            font-weight: 600;

            .line-message-close {
                cursor: pointer;
                display: flex;
            }
        }

        .card-content {
            padding: 20px;
            text-align: center;

            img {
                width: auto;
                border: 1px solid #ccc;
                margin-bottom: 20px;
                max-width: 150px;
            }

            .title {
                font-size: 18px;
                font-weight: 600;
            }

            .subtitle {
                color: #727171;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 0;
            }
        }
    }

    //Floating content
    .floating-content {
        position: fixed;
        bottom: 25px;
        right: 20px;
        z-index: 100;

        .line-popup {
            margin-bottom: 15px;
            cursor: pointer;

            svg {
                filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.1));
            }

            @include media-breakpoint-up(md) {
                &.mobile-link {
                    display: none;
                }
            }

            @include media-breakpoint-down(md) {
                &.desktop {
                    display: none;
                }
            }
        }



        .back-to-top {
            background: white;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            color: $secondary;
            text-align: center;
            display: grid;
            filter: drop-shadow(0px 4px 11px rgba(0, 0, 0, 0.1));
            font-size: 13px;
            margin: auto;

            .back-content-wrapper {
                display: grid;

                svg {
                    margin: auto;
                    position: relative;
                    top: 2px;
                }
            }

            &:hover {
                background-color: $primary-accent;
                text-decoration: none;

                color: white;

                svg path {
                    fill: white;
                }
            }
        }
    }

    .wave {
        position: relative;
        top: 2px;
    }

    .footer-wrapper {
        background: $footer;
    }

    .footer-inner-wrapper {
        display: flex;
        justify-content: space-between;
        padding-top: 50px;
        text-align: center;

        & > div {
            padding-bottom: 40px;
        }

        @include media-breakpoint-down(sm) {
            display: block;
        }
    }

    .footer-menu-wrapper {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            position: relative;
            top: 8px;
            display: flex;
            flex-wrap: wrap;

            @include media-breakpoint-down(sm) {
                justify-content: center;
            }


            li {
                margin: 0 15px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 30px;
                }

                a {
                    color: #333!important;
                    font-weight: 400;

                    &:hover {
                        color: $secondary!important;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .footer-social-wrapper {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            display: flex;
            justify-content: center; 

            li {
                margin: 0 15px;

                a:hover {
                    path {
                        fill: $secondary;
                    }
                }
            }
        }
    }

    .copyright {
        background-color: $footer-accent;
        padding: 15px;

        p {
            color: white;
            font-size: 12px;
            font-weight: 300;
            line-height: 20px;
            text-align: left;
            margin-bottom: 0;

            @include media-breakpoint-down(sm) {
                text-align: center;
            }
        }
    }
}