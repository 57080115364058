.swiper-pagination {
    z-index: 1!important;
}

//彈跳視窗
#popup {
    .swiper-button-prev, .swiper-button-next {
        width: 60px;
        height: 60px;
        z-index: 1;
        background: no-repeat center center url("build/src/img/arrow.svg");
        background-size: 50%;


        &:after {
            color: transparent;
            transform: scale(0.5);
        }

        @include media-breakpoint-down(lg) {
            width: 45px;
            height: 45px;
        }
    }

    .swiper-button-next {
        position: absolute;
        transform: rotate(180deg);
    }

    .swiper-button-next {
        right: 20px;

        @include media-breakpoint-down(sm) {
            right: 0px;
        }
    }

    .swiper-button-prev {
        left: 20px;

        @include media-breakpoint-down(sm) {
            left: 0px;
        }
    }

    .swiper-slide {
        img {
            mask-image: url("build/src/img/pop-up_mask.png");
            -webkit-mask-position: center;
            -webkit-mask-size: cover;
            -webkit-mask-repeat: no-repeat;
        }
    }
}

//首頁輪播
#banner {
    position: relative;

    @include media-breakpoint-up(sm) {
        .mobile-banner {
            display: none;
        }
    }

    @include media-breakpoint-down(sm) {
        .desktop-banner {
            display: none;
        }

        .mobile-banner {
            display: block;
            margin-top: 60px;
        }
    }

    .swiper-slide {
        height: auto;
    
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }

    .swiper-pagination {
        bottom: 100px;

        @include media-breakpoint-down(sm) {
            bottom: 20px;
        }

        .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            margin: 0 10px;
            background: white;
        }

        .swiper-pagination-bullet-active {
            background: $primary-accent;
        }
    }
}


//首頁幸福資訊輪播
#news {
    .swiper-container {
        margin-bottom: 40px;
        padding-bottom:60px;
        overflow: hidden;
    }

    .swiper-button-prev, .swiper-button-next {
        width: 60px;
        height: 60px;
        z-index: 1;
        background: no-repeat center center url("build/src/img/arrow.svg");
        background-size: 50%;


        &:after {
            color: transparent;
            transform: scale(0.5);
        }

        @include media-breakpoint-down(lg) {
            width: 45px;
            height: 45px;
        }
    }

    .swiper-button-prev {
        position: absolute;
        left: 50px;
    }

    .swiper-button-next {
        position: absolute;
        right: 50px;
        transform: rotate(180deg);
    }

    @include media-breakpoint-down(lg) {
        .swiper-button-prev {
            left: 0px;
        }
    
        .swiper-button-next {
            right: 0px;
        }
    }

    @include media-breakpoint-down(sm) {
        .swiper-slide {
            padding: 0 30px;
        }
    }
}

.menu-flip {
    position: relative;

    .swiper-container {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
    }

    .swiper-button-prev, .swiper-button-next {
        width: 60px;
        height: 60px;
        background: no-repeat center center url("build/src/img/arrow.svg");
        background-size: 50%;

        &:after {
            color: transparent;
            transform: scale(0.5);
        }

        @include media-breakpoint-down(lg) {
            width: 45px;
            height: 45px;
        }
    }

    .swiper-button-next {
        right: 0;
        transform: rotate(180deg);
    }

    .swiper-button-prev {
        left: 0;
    }

    @media only screen and (min-width: 769px) {
        .swiper-slide:first-child {
          transition: transform 100ms;
        }

        .swiper-slide:first-child img {
          transition: box-shadow 500ms;
        }

        .swiper-slide.swiper-slide-active:first-child {
          transform: translateX(50%);
          z-index: 2;
        }

        .swiper-slide:nth-child(2) {
          transition: transform 100ms;
        }

        .swiper-slide.swiper-slide-next:nth-child(2) {
          transform: translateX(55%);
          z-index: 1;
        }

        .swiper-container[dir="rtl"]
          .swiper-slide.swiper-slide-active:first-child {
          transform: translateX(-50%);
        }

        .swiper-container[dir="rtl"]
          .swiper-slide.swiper-slide-next:nth-child(2) {
          transform: translateX(-55%);
        }
    }

    @media only screen and (min-width: 1600px) {
        .swiper-slide:first-child {
          transition: transform 100ms;
        }

        .swiper-slide:first-child img {
          transition: box-shadow 500ms;
        }

        .swiper-slide.swiper-slide-active:first-child {
          transform: translateX(140%);
          z-index: 2;
        }

        .swiper-slide:nth-child(2) {
          transition: transform 100ms;
        }

        .swiper-slide.swiper-slide-next:nth-child(2) {
          transform: translateX(140%);
          z-index: 1;
        }

        .swiper-container[dir="rtl"]
          .swiper-slide.swiper-slide-active:first-child {
          transform: translateX(-50%);
        }

        .swiper-container[dir="rtl"]
          .swiper-slide.swiper-slide-next:nth-child(2) {
          transform: translateX(-55%);
        }
    }

    @media only screen and (max-width: 768px) {
        .swiper-slide {
            padding: 0 30px;
        }
    }
}