.about {
    .stripe-accent-1 {
        position: absolute;
        max-width: 500px;
        width: 100%;
        top: -50px;
        left: -120px;
    }

    #about-intro {
        min-height: 1100px;

        @include media-breakpoint-down(lg) {
            min-height: 1000px;
        }

        @include media-breakpoint-down(sm) {
            min-height: 900px;
        }

        .div-wrapper {
            position: relative;
        }  

        .div {
            position: relative;
            top: -1px;
            padding-bottom: 60px;
        }

        .row {
            @include media-breakpoint-down(md) {
                &:before {
                    left: 57%;
                    top: 60px;
                }
            }

            @include media-breakpoint-down(sm) {
                &:before {
                    transform: scale(0.4);
                }
            }
        }

        @include media-breakpoint-down(sm) {
            .section-title {
                margin-bottom: 0;
            }
        }

        .slider {
            z-index: 1;
            position: relative;
            top: 250px;
            mask-image: url("build/src/img/slider-mask.png");
            left: -5%;
            -webkit-mask-position: center;
            -webkit-mask-size: contain;
            -webkit-mask-repeat: no-repeat;

            @include media-breakpoint-down(md) {
                top: 0;
                left: -18%;
                transform: scale(0.8);
            }


        }

        .about-content {
            position: relative;
            max-width: 1400px;
            margin: auto;

            &:before {
                content: url("build/src/img/波浪線.png");
                transform: scale(0.7);
                position: absolute;
                bottom: -500px;
                right: -400px;

                @include media-breakpoint-down(md) {
                    bottom: -200px;
                }

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }

            @include media-breakpoint-up(md) {
                position: relative;
                top: -100px;
            }
        }

        .text {
            background: no-repeat center url("build/src/img/about-text-bg.png");
            background-size: contain;
            position: relative;
            min-height: 900px;
            aspect-ratio: 1/1;
            position: absolute;
            right: -5%;

            @include media-breakpoint-down(md) {
                position: relative;
                top: -30%;
                min-height: unset;
                background-position: center;
                background-size: cover;
                right: unset;
            }

            .content-wrapper {
                display: grid;
                align-items: center;
                height: 100%;
                justify-content: center;

                @include media-breakpoint-down(sm) {
                    height: 120%;
                }

                .content-inner-wrapper {
                    text-align: left;
                    padding: 6em 8em 6em 15em;

                    @include media-breakpoint-down(lg) {
                        padding: 6em 8em 6em 13em;
                    }

                    @include media-breakpoint-down(sm) {
                        padding: 8em 2em 6em 3em;
                    }

                    .content-title {
                        color: $secondary;

                        &:after {
                            content: '';
                            display: block;
                            width: 100px;
                            height: 2px;
                            background-color: $secondary;
                            margin-top: 20px;

                            @include media-breakpoint-down(sm) {
                                display: none;
                            }
                        }
                    }

                    p {
                        text-align: justify;
                        margin-bottom: 30px;
                        line-height: 2;

                        @include media-breakpoint-down(sm) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    #about-columns {
        padding-bottom: 100px;

        .about-content-row {
            align-items: center;
            margin-bottom: 100px;

            .text-wrapper {
                h4 {
                    color: $content;
                    margin-bottom: 20px;

                    @include media-breakpoint-down(md) {
                        text-align: center;
                    }
                }

                p {
                    line-height: 2;

                    @include media-breakpoint-down(md) {
                        text-align: justify;
                    }
                }
            }

            .img-wrapper {
                text-align: center;

                img {
                    max-width: 500px;
                }

                @include media-breakpoint-down(md) {
                    margin-bottom: 30px;
                }
            }

            &:nth-of-type(even) {
                @include media-breakpoint-up(md) {
                    flex-direction: row-reverse;

                    .text-wrapper {
                        text-align: right;
                    }
                }
            }
        }

        .group {
            position: relative;
            padding: 0 30px;
        }

        //block accents + decorations
        .about-intro-accent {
            position: absolute;
            max-width: 1400px;
            left: -650px;
            transform: translateY(-400px);

            @include media-breakpoint-down(md) {
                left: -300px;
                min-width: 600px;
                transform: translateY(-160px);
            }
        }

        .chopstick-accent {
            position: absolute;
            max-width: 160px!important;
            z-index: 1;
            left: 0px;

            @include media-breakpoint-down(sm) {
                width: 80px;
                left: 20px;
            }
        }

        .rice-accent {
            max-width: 120px;
            position: absolute;
            right: -20px;
            top: 327px;
            z-index: -1;

            @include media-breakpoint-down(sm) {
                width: 60px;
                right: 20px;
                top: 255px;
            }
        }

        .first-yellow-block {
            max-width: 500px;
            position: absolute;
            z-index: -2;
            right: -250px;
            top: 400px;
        }

        .salmon {
            max-width: 150px;
            position: absolute;

            @include media-breakpoint-down(sm) {
                width: 60px;
                transform: translateY(350px);
            }
        }

        .soy-sauce {
            position: absolute;
            max-width: 200px;
            right: -200px;
            z-index: 1;

            @include media-breakpoint-down(sm) {
                width: 100px;
                right: 0;
                transform: translateY(27px);
            }
        }

        .second-yellow-block {
            position: absolute;
            max-width: 400px;
            transform: translate(290px, -350px);
            z-index: -1;

            @include media-breakpoint-down(sm) {
                width: 250px;
                transform: translate(75px, -420px);
            } 
        }

        .third-yellow-block {
            transform: rotateX(180deg) translate(500px, 850px);
            position: absolute;
            z-index: -1;

            @include media-breakpoint-down(sm) {
                width: 550px;
                transform: rotateX(180deg) translate(-50px, 580px);
            }
        }
        
        .tea {
            position: absolute;
            max-width: 70px;
            transform: translate(80px, -50px);
            z-index: 1;

            @include media-breakpoint-down(sm) {
                width: 50px;
                transform: translateY(55px, -50px);
            }
        }

        .fourth-yellow-block {
            position: absolute;
            max-width: 550px;
            transform: translate(-350px, -150px);
            z-index: -1;

            @include media-breakpoint-down(sm) {
                width: 250px;
                transform: translate(-80px, -100px);
            }
        }

        .fifth-yellow-block {
            position: absolute;
            max-width: 400px;
            z-index: 1;
            transform: translate(950px, -200px);

            @include media-breakpoint-down(sm) {
                width: 200px;
                right: -70px;
                transform: translateY(-90px);
            }
        }

        .cabbage {
            position: absolute;
            max-width: 70px;
            transform: translate(350px, -250px);

            @include media-breakpoint-down(sm) {
                width: 50px;
                transform: translate(50px, -100px);
                z-index: -1;
            }
        }

        .sixth-yellow-block {
            max-width: 700px;
            position: absolute;
            transform: translate(-380px, -320px);
            z-index: -2;

            @include media-breakpoint-down(sm) {
                width: 300px;
                transform: translate(-100px, -180px);
            }
        }
    }
    
}