.store {
    .store-content-wrapper {
        padding: 0 40px 150px;
    }

    .note {
        background-color: #FFF0C9;
        border-radius: 20px;
        padding: 10px;
        margin-bottom: 40px;

        p {
            text-align: center;
            margin-bottom: 0;
        }
    }

    .links {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 40px;

        @include media-breakpoint-down(md) {
            justify-content: center;
        }

        .map {
            i {
                font-size: 30px;
                position: relative;
                left: 5px;
                top: 1px;
            }

            &:hover {
                i {
                    color: #FFF0C9;
                }
            }
        }

        .tel {
            i {
                font-size: 28px;
                position: relative;
                left: 2px;
                top: 2px;
            }

            &:hover {
                i {
                    color: #FFF0C9;
                }
            }
        }


        a {
            color: $secondary;
            padding: 9px 10px;
            border: 2px solid $secondary;
            border-radius: 50px;
            line-height: 1;
            margin: 0 5px;
            min-width: 56px;
            max-height: 54px;

            &:hover {
                background: $secondary;
                color: white;
            }
        }
    }

    .store-detail-content {
        .row {
            flex-direction: row-reverse;
            margin-bottom: 40px;
        }
    }

    .store-detail {
        h4 {
            position: relative;
            color: $secondary;
            margin-bottom: 20px;
            width: max-content;
            
            &:after {
                content: '';
                display: block;
                height: 2px;
                width: 100%;
                background-color: $secondary;
                margin-top: 5px;
                border-radius: 50px;
            }
        }

        div, a {
            color: $secondary;
        }

        div {
            margin-bottom: 3px;
            line-height: 2;
        }
    }

    .parking-info {
        label {
            color: $secondary;
            font-size: 20px;
            font-weight: 600;
        }

        p {
            line-height: 2;
        }
    }
}